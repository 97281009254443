<template>
  <Card
    :image="image"
    :title="title"
    :description="description"
    :url="path._url || path.url"
    :languages="languages"
    isPath
  >
    <template v-slot:footer-left>
      <div
        v-if="
          !isLoggedIn && slug === 'prirucka-prezitia-na-divokom-webe' && public
        "
        class="d-flex align-items-center w-color-dark-green"
      >
        <svg-open-lock-icon class="open-lock-icon" />
        <span>Bez prihlásenia</span>
      </div>
    </template>
    <template v-slot:footer-right>
      <ToggleStarred
        class="star"
        :model="
          slug !== 'prirucka-prezitia-na-divokom-webe' ? 'path' : 'course'
        "
        :id="path.id"
        :starred="starred"
        :starredText="
          slug !== 'prirucka-prezitia-na-divokom-webe'
            ? 'Séria bola pridaná medzi Moje kurzy!'
            : undefined
        "
        :unstarredText="
          slug !== 'prirucka-prezitia-na-divokom-webe'
            ? 'Séria bola odstránená z Mojich kurzov!'
            : undefined
        "
      />
    </template>
  </Card>
</template>

<script>
import { get } from "lodash";
import { mapGetters } from "vuex";
import Card from "/components/Card.vue";

export default {
  components: {
    Card,
    ToggleStarred: () => import("/components/ToggleStarred.vue"),
    "svg-open-lock-icon": () => import("/assets/icons/openLock.svg?inline"),
  },
  props: {
    path: { required: true, type: Object },
  },
  data() {
    return {
      image: get(
        this.path,
        "attributes.thumbnail.data.attributes.formats.small.url"
      ),
      title: get(this.path, "attributes.name"),
      description: get(this.path, "attributes.description"),
      slug: get(this.path, "attributes.slug"),
      public: get(this.path, "attributes.public"),
      languages: get(this.path, "attributes.subtitleLanguages.data"),
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    starred() {
      return (
        this.isLoggedIn &&
        (this.user.favoritePaths || []).some(({ id }) => id === this.path.id)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.z-card {
  width: initial !important;

  .footer {
    a {
      font-family: "Boing", sans-serif;
    }
  }
}

.open-lock-icon {
  width: 1.5rem;
  margin-right: 0.5rem;
}

.star {
  position: relative;
}
</style>
