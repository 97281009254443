var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"image":_vm.image,"title":_vm.title,"description":_vm.description,"url":_vm.path._url || _vm.path.url,"languages":_vm.languages,"isPath":""},scopedSlots:_vm._u([{key:"footer-left",fn:function(){return [(
        !_vm.isLoggedIn && _vm.slug === 'prirucka-prezitia-na-divokom-webe' && _vm.public
      )?_c('div',{staticClass:"d-flex align-items-center w-color-dark-green"},[_c('svg-open-lock-icon',{staticClass:"open-lock-icon"}),_c('span',[_vm._v("Bez prihlásenia")])],1):_vm._e()]},proxy:true},{key:"footer-right",fn:function(){return [_c('ToggleStarred',{staticClass:"star",attrs:{"model":_vm.slug !== 'prirucka-prezitia-na-divokom-webe' ? 'path' : 'course',"id":_vm.path.id,"starred":_vm.starred,"starredText":_vm.slug !== 'prirucka-prezitia-na-divokom-webe'
          ? 'Séria bola pridaná medzi Moje kurzy!'
          : undefined,"unstarredText":_vm.slug !== 'prirucka-prezitia-na-divokom-webe'
          ? 'Séria bola odstránená z Mojich kurzov!'
          : undefined}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }